<template>
  <div
    ref="moduleElement"
    :class="['module', hlp_background?.animations]"
    :data-is-primary-asset-loaded="isPrimaryAssetLoaded"
    :data-is-module-in-view="isModuleInView"
    :style="{
      backgroundColor: hlp_background?.color,
    }"
  >
    <h2 v-if="seo_heading" class="hidden-heading">{{ seo_heading }}</h2>
    <component
      :is="hlp_background?.link_url ? linkComponent : 'div'"
      v-bind="hlp_background?.link_url ? { to: localePath(hlp_background?.link_url) } : {}"
      class="primary-asset-container"
    >
      <div v-if="hasImageBackground" class="picture-wrapper">
        <Picture :source-set="hlp_background?.image?.source_set" />
      </div>

      <div v-if="hasVideoBackground" class="video-wrapper">
        <HlpVideo
          :source-set="hlp_background?.video?.source_set"
          :autoplay="hlp_background?.video?.settings.autoplay"
          :loop="hlp_background?.video?.settings.loop"
          :muted="true"
          :playsinline="true"
          :controls="false"
          :preload="hlp_background?.video?.settings.preload"
          :lazy-load="hlp_background?.video?.settings.lazy_load"
          :pause-on-scroll-out="hlp_background?.video?.settings.pause_on_scroll_out"
          :reset-on-scroll-out="hlp_background?.video?.settings.reset_on_scroll_out"
          @data-loaded="handleDataLoaded"
        />
      </div>
    </component>

    <HlpModuleSubcomponents :components="components" />
  </div>
  <div v-if="false"><!-- prevent props from being passed to the root element --></div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import HlpVideo from './HlpVideo.vue';
import Picture from './Picture.vue';
import { Breakpoint } from '~/configs/breakpoints';
import { AssetCloudinary } from '~/types/contentstack';

const localePath = useLocalePath();
const linkComponent = resolveComponent('NuxtLink');

/* eslint-disable vue/prop-name-casing */
const props = defineProps<{
  seo_heading: string;
  hlp_background: {
    color: string;
    link_url: string;
    animations: string[];
    image: {
      source_set: {
        cloudinary_asset: AssetCloudinary[];
        breakpoint: Breakpoint;
        transform?: {
          width: number;
          height: number;
        };
      }[];
    };
    video: {
      source_set: {
        cloudinary_asset: AssetCloudinary[];
        transform?: {
          width: number;
          height: number;
        };
        poster: {
          cloudinary_asset: AssetCloudinary[];
          transform?: {
            width: number;
            height: number;
          };
        };
        breakpoint: Breakpoint;
      }[];
      settings: {
        autoplay?: boolean;
        loop?: boolean;
        muted?: boolean;
        plays_inline?: boolean;
        controls?: string; // @todo
        pause_on_scroll_out?: boolean;
        reset_on_scroll_out?: boolean;
        preload?: HTMLVideoElement['preload'];
        lazy_load?: boolean;
      };
    };
  };
  components: Object;
}>();

function handleDataLoaded() {
  isPrimaryAssetLoaded.value = true;
}

const moduleElement = ref(null);
const isPrimaryAssetLoaded = ref(false);
const isModuleInView = ref(false);

const hasImageBackground = props.hlp_background?.image?.source_set.length !== 0;
// @note: videos are not shown if image backgrounds are configured
const hasVideoBackground = !hasImageBackground && props.hlp_background?.video?.source_set.length !== 0;

let intersectionObserver: IntersectionObserver | null = null;

onMounted(() => {
  // Initialize the IntersectionObserver
  intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      isModuleInView.value = entry.isIntersecting;
    });
  });

  // Start observing the element if it exists
  if (moduleElement.value) {
    intersectionObserver.observe(moduleElement.value);
  }
});

onUnmounted(() => {
  // Clean up the observer when the component is unmounted
  if (intersectionObserver) {
    intersectionObserver.disconnect();
    intersectionObserver = null;
  }
});
</script>

<style lang="scss" scoped>
.module {
  display: grid;
  grid-template-areas: 'stack';
  transition: opacity 1s, transform 1s;
}

.hidden-heading {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.primary-asset-container {
  grid-area: stack;
}

a.primary-asset-container video {
  pointer-events: none;
}

// Animations
.fx-fade-in-on-load {
  opacity: 0;

  &[data-is-primary-asset-loaded='true'] {
    opacity: 1;
  }
}

.fx-scale-down-on-load {
  transform: scale(1.3);

  &[data-is-primary-asset-loaded='true'] {
    transform: scale(1);
  }
}

.fx-scale-down-on-intersect {
  &[data-is-module-in-view='false'] {
    transform: scale(1.2);
  }
}

.fx-fade-in-on-intersect {
  opacity: 0.1;

  &[data-is-module-in-view='true'] {
    opacity: 1;
  }
}
</style>
